import React from "react";
import PropTypes from "prop-types";

import ThumbFeedWedgeChart from "./ThumbFeedWedgeChart";
import ThumbChart from "./ThumbChart";
import FarmDetails from "./FarmDetails";

export class TableRow extends React.Component {
  static propTypes = {
    farm: PropTypes.object.isRequired,
  };

  state = { showDetails: false };

  render() {
    const { farm } = this.props;
    if (farm.coverReadings.length < 3) {
      return (
        <tr key={farm.id}>
          <td className="alignLeft">Insufficient data for {farm.name}</td>
        </tr>
      );
    }

    const readingValues = (field) =>
      farm.coverReadings.map((r) => (
        <div key={`${farm.id}-${r.readingNumber}`}>{r[field]}</div>
      ));

    const trReadingValues = (field) => (
      <td className="numberValue">{readingValues(field)}</td>
    );

    const trReadingChartValues = (field, options = {}) => (
      <td className="numberValueChart">
        <div className="chartWrapper">
          <ThumbChart
            chartType={options.chartType || "Line"}
            data={readingChartValues(field)}
          />
        </div>
        <div className="valueWrapper">{readingValues(field)}</div>
      </td>
    );

    const readingChartValues = (field) => {
      const readings = Object.assign([], farm.coverReadings).reverse();
      return readings.map((r) => ({
        name: r.readingNumber,
        value: r[field],
      }));
    };

    return (
      <tr key={farm.id}>
        <td className="alignLeft">
          <a href="#" onClick={this.selectFarm}>
            {farm.name}
          </a>
        </td>
        {trReadingChartValues("averageCover")}
        {trReadingValues("coverChange")}
        {trReadingChartValues("averageGrowth")}
        {trReadingValues("daysSincePrevWalk")}
        {trReadingValues("dateFormatted")}

        <td style={{ padding: 0 }} onClick={this.selectFarm}>
          <ThumbFeedWedgeChart farm={farm} />
        </td>
        <td>
          {this.state.showDetails && (
            <FarmDetails farm={this.props.farm} onClose={this.closeForm} />
          )}
        </td>
      </tr>
    );
  }

  selectFarm = (e) => {
    e.preventDefault();
    this.setState({ showDetails: true });
  };

  closeForm = () => this.setState({ showDetails: false });
}

export class TableHeader extends React.Component {
  render() {
    return (
      <thead>
        <tr>
          <th className="alignLeft">Farm</th>
          <th className="numberValue">Cover</th>
          <th className="numberValue">Change</th>
          <th className="numberValue">Growth</th>
          <th className="numberValue">Days</th>
          {/* Days since <br />last walk
          </th> */}
          <th className="numberValue">Date</th>
          <th>Click chart to zoom</th>
        </tr>
      </thead>
    );
  }
}
