import React from "react";
import PropTypes from "prop-types";
import FarmList from "./FarmList";

export default class GroupSummaryReport extends React.Component {
  static propTypes = { list: PropTypes.array };

  render() {
    return (
      <div className="GroupSummaryReport">
        <h2>Group summary report</h2>
        <FarmList list={this.props.list} />
      </div>
    );
  }
}
