import React from "react";
import PropTypes from "prop-types";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

export default class ThumbFeedWedgeChart extends React.Component {
  static propTypes = {
    farm: PropTypes.object.isRequired
  };

  render() {
    const colors = [
      "#666666",
      "#006600",
      "#FF0000",
      "#0000FF",
      "#FFFF00",
      "#7F00FF",
      "#FF0080"
    ];

    const { farm } = this.props;
    if (!farm.coverReadings[0]) {
      return null;
    }
    const data = farm.coverReadings[0].paddockReadings;

    return (
      <div>
        <BarChart width={200} height={70} data={data}>
          <Bar dataKey="cover">
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[entry.blockId]} />
            ))}
          </Bar>
        </BarChart>
      </div>
    );
  }
}
