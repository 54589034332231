import React from "react";

import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import { graphql } from "react-apollo";
import gql from "graphql-tag";

const link = createHttpLink({
  uri: "/graphql",
  withCredentials: true,
  credentials: "same-origin" // todo test it without this
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

import GroupSummaryReport from "./GroupSummaryReport";

export default class GroupSummary extends React.Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <GroupSummaryGraphWithData />
      </ApolloProvider>
    );
  }
}

const query = gql`
  {
    farms {
      id
      name
      farmaddr1
      farmaddr2
      coverReadings(limit: 3) {
        date
        dateFormatted
        daysSincePrevWalk
        averageCover
        coverChange
        averageGrowth
        readingNumber
        paddockReadings(minCover: 1) {
          blockId
          paddockName
          cover
          totalCover
        }
      }
    }
  }
`;

const GroupSummaryGraphWithData = graphql(query)(props => (
  <GroupSummaryGraphQL graphQl={props} />
));

class GroupSummaryGraphQL extends React.Component {
  render() {
    if (this.props.graphQl.data.loading) {
      return "loading....";
    }

    return <GroupSummaryReport list={this.props.graphQl.data.farms} />;
  }
}
