import React from "react";
import PropTypes from "prop-types";

import { AreaChart, Area } from "recharts";

export default class ThumbLineChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  render() {
    const { data } = this.props;
    return (
      <div>
        <AreaChart width={50} height={50} data={data}>
          <Area
            dataKey="value"
            type="monotone"
            fill="#82ca9d"
            stroke="#82ca9d"
            strokeWidth={2}
          />
        </AreaChart>
      </div>
    );
  }
}
