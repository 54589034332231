import React from "react";
import PropTypes from "prop-types";

import ThumbLineChart from "./ThumbLineChart";
import ThumbAreaChart from "./ThumbAreaChart";

export default class ThumbChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    chartType: PropTypes.string.isRequired
  };

  render() {
    const { data, chartType } = this.props;
    if (chartType == "Line") {
      return <ThumbLineChart data={data} />;
    } else {
      return <ThumbAreaChart data={data} />;
    }
  }
}
