import React from "react";
import PropTypes from "prop-types";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";

import FeedWedgeChart from "./FeedWedgeChart";

export default class FarmDetails extends React.Component {
  static propTypes = {
    farm: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };
  state = { show: true };

  render() {
    const { farm } = this.props;

    if (!farm.coverReadings[0]) {
      return null;
    }
    return (
      <div>
        <Modal size="large" show={this.state.show} onHide={this.close}>
          <Modal.Header>
            <button className="close" type="button" onClick={this.close}>
              <span aria-hidden="true">×</span>
            </button>
            <Modal.Title className="title">{farm.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="uncontrolled-tab-example">
              {farm.coverReadings.map((r) => (
                <Tab
                  key={r.readingNumber}
                  eventKey={r.readingNumber}
                  title={r.dateFormatted}
                >
                  <span className="detailsBlock">
                    <span>Average cover: {r.averageCover}</span>
                    <span>Average growth: {r.averageGrowth}</span>
                  </span>
                  <FeedWedgeChart readings={r.paddockReadings} />
                </Tab>
              ))}
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  close = () => {
    this.setState({ show: false });
    this.props.onClose();
  };
}
