import React from "react";
import PropTypes from "prop-types";

import { TableHeader, TableRow } from "./TableRow";

export default class FarmList extends React.Component {
  static propTypes = {
    list: PropTypes.array
  };

  render() {
    const { list } = this.props;

    if (list == null) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <table className="table table-hover">
          <TableHeader />
          <tbody>
            {list.map(farm => (
              <TableRow farm={farm} key={farm.id} />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
