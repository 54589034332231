import React from "react";
import PropTypes from "prop-types";

import { LineChart, Line, ReferenceLine } from "recharts";

const colorBreakPoint = 0;

export default class ThumbLineChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  render() {
    const { data } = this.props;

    return (
      <div>
        <LineChart width={50} height={50} data={data}>
          <ReferenceLine y={0} stroke="#999999" strokeDasharray="3 3" />
          <Line
            dataKey="value"
            type="monotone"
            stroke="#333333"
            strokeWidth={1}
            dot={false}
          />
        </LineChart>
      </div>
    );
  }
}

// import { LineChart, Line, Cell } from "recharts";

// const colorBreakPoint = 0;

// export default class ThumbLineChart extends React.Component {
//   static propTypes = {
//     data: PropTypes.array.isRequired
//   };

//   render() {
//     const { data } = this.props;
//     const { min, max } = data.reduce(
//       (result, dataPoint) => ({
//         min:
//           dataPoint.value < result.min || result.min === 0
//             ? dataPoint.value
//             : result.min,
//         max:
//           dataPoint.value > result.max || result.max === 0
//             ? dataPoint.value
//             : result.max
//       }),
//       { min: 0, max: 0 }
//     );

//     const colorBreakPointPercentage = `${(1 -
//       (colorBreakPoint - min) / (max - min)) *
//       100}%`;
//     return (
//       <div>
//         <LineChart width={50} height={50} data={data}>
//           <defs>
//             <linearGradient id="colorUv" x1="0%" y1="0%" x2="0%" y2="100%">
//               <stop offset="0%" stopColor="black" />
//               <stop offset={colorBreakPointPercentage} stopColor="black" />
//               <stop offset={colorBreakPointPercentage} stopColor="red" />
//               <stop offset="100%" stopColor="red" />
//             </linearGradient>
//           </defs>
//           <Line
//             type="monotone"
//             dataKey="value"
//             stroke="url(#colorUv)"
//             strokeWidth={2}
//             dot={false}
//             activeDot={false}
//           />

//           {/* <Line
//             dataKey="value"
//             type="monotone"
//             stroke="#8884d8"
//             strokeWidth={2}
//             dot={false}
//           /> */}
//         </LineChart>
//       </div>
//     );
//   }
// }
