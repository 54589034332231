import React from "react";
import PropTypes from "prop-types";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

export default class FeedWedgeChart extends React.Component {
  static propTypes = {
    readings: PropTypes.array.isRequired
  };

  render() {
    const { readings } = this.props;

    const colors = [
      "#666666",
      "#006600",
      "#FF0000",
      "#0000FF",
      "#FFFF00",
      "#7F00FF",
      "#FF0080"
    ];
    return (
      <div style={{ height: "500px" }}>
        <ResponsiveContainer minWidth={300} width="100%" height={500}>
          <BarChart data={readings}>
            <XAxis
              dataKey="paddockName"
              interval={1}
              tickCount={100}
              height={100}
              width={5}
              tick={<CustomizedAxisTick />}
            />
            <YAxis dataKey="cover" />
            <Tooltip content={<CustomTooltip />} />

            <Bar dataKey="cover">
              {readings.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[entry.blockId]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const CustomizedLabel = ({ x, y, stroke, payload }) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {payload.value}
  </text>
);

const CustomizedAxisTick = ({ x, y, stroke, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={-10}
      y={-10}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-90)"
    >
      {payload.value}
    </text>
  </g>
);

class CustomTooltip extends React.Component {
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div className="customTooltip">
          <p>Paddock: {payload[0].payload.paddockName}</p>
          <p>Cover: {payload[0].payload.cover}</p>
          <p>Total Cover: {payload[0].payload.totalCover}</p>
        </div>
      );
    }

    return null;
  }
}
